import React, { useEffect, useState } from "react";
import ValidationText from "../../../utils/validation/ValidationText";
import ImageCropperWithUpload from "../../ImageUpload/UploadImage";
import Selector from "../../dropdown/commonDropdown/Selector";
import AdditionalDescription from "./AdditionalDescription";
import AdditionalSpeaker from "./AdditionalSpeaker";
import AdditionalModerator from "./AdditionalModerator";
import TextInput from "../../UIComponent/TextInput";
import { DeleteButtonIcon, SettingsButtonIcon } from "../../SVGConstants";
// import EntryManagementServices from "../../../services/axiosServices/apiServices/EntryManagementServices";
// import SwalServices from "../../../services/swalServices/SwalServices";
import moment from "moment";
// import { scroller } from 'react-scroll'
import { convertToDecimal } from "../../../utils/Utils";
import TimePicker from "../../TimePicker.js/TimePicker";
import TinyMCEEditor from "../../ckEditor/TinyMCEEditor";


const earlyPackageObj = {
  eventPriceId: 0,
  eventPackageId: 0,
  earlyBirdPrice: 0,
  earlyBirdCredit: 0,
  isSuperEarlyBirdPrice: false,
  promotionStartDate: '',
  promotionEndDate: '',
  maxQuantity: 0
};

const superEarlyPackageObj = {
  eventPriceId: 0,
  eventPackageId: 0,
  earlyBirdPrice: 0,
  earlyBirdCredit: 0,
  isSuperEarlyBirdPrice: true,
  promotionStartDate: '',
  promotionEndDate: '',
  maxQuantity: 0
};

const packagePriceObj = {
  eventTimeSlotId: 0,
  eventId: 0,
  eventPackageId: 0,
  eventPackageName: 'Non Member',
  eventPrice: 0,
  eventCredit: 0,
  entryPackagePrice: [
    earlyPackageObj,
    superEarlyPackageObj
  ]
};



function BasicInformation(props) {
  // const goToViolation = (id) => {
  //   let violation = document.getElementById(id);
  //   window.scrollTo({
  //     top: violation.offsetTop,
  //     behavior: "smooth",
  //   });
  // };



  const [isEdit, setIsEdit] = useState(0);
  const [isAdd, setIsAdd] = useState(0);
  const [packageList, setPackageList] = useState([]);
  const [isCreditAllow, setIsCreditAllow] = useState(false);



  useEffect(() => {
    // let eventId = props.eventId;
    // let eventTimeSlotId = props.eventTimeSlotId;

    let packageObj = { ...packagePriceObj }
    packageObj.eventTimeSlotId = props.eventTimeSlotId;

    // Akshay : To do : Uncomment bellow code while Credit Allow
    // setIsCreditAllow(props.basicInformationDetails.isCreditAllow);
    setIsCreditAllow(false);

    if (props.packageList && props.packageList.length > 0) {
      setPackageList(props.packageList);
    }
    else {
      let pack = [];
      pack.push(packageObj);
      setPackageList(pack);
    }
    if (props.basicInformationDetails.isAttendanceFree === "true" || props.basicInformationDetails.isAttendanceFree === true) {
      // setPackageList(props.startupMember);
      let detail = [];
      const packageObj = {
        eventPackageId: 0,
        eventPackageName: "",
        eventPrice: 0,
        eventCredit: 0,
        eventTimeSlotId: props.eventTimeSlotId,
        entryPackagePrice: [
          {
            eventPriceId: 0,
            eventPackageId: 0,
            earlyBirdPrice: 0,
            earlyBirdCredit: 0,
            isSuperEarlyBirdPrice: false,
            promotionStartDate: '',
            promotionEndDate: '',
            maxQuantity: 0
          },
          {
            eventPriceId: 0,
            eventPackageId: 0,
            earlyBirdPrice: 0,
            earlyBirdCredit: 0,
            isSuperEarlyBirdPrice: true,
            promotionStartDate: '',
            promotionEndDate: '',
            maxQuantity: 0
          }
        ]
      }
      props.startupMember.forEach((pkgName, pkgIndex) => {
        let pkgToAdd = { ...packageObj };
        if (pkgName.label !== 'Others') {
          pkgToAdd.eventPackageName = pkgName.label;
          detail.push(pkgToAdd);
        }
      });
      setPackageList(detail)
    }
  }, [props.packageList]);




  const handleChange = (e, key, identity) => {
    const { name, value } = e.target;
    let data = [...packageList];
    let detail = { ...data[key] };

    if (name === "eventPrice" || name === "eventCredit") {
      const value = e.target.value;
      const convertedValue = convertToDecimal(value);
      detail[name] =
        convertedValue || convertedValue === 0 ? convertedValue : value;
    } else if (name === "eventPackageName") {
      if (detail.eventPackageName === "Others") {
        detail.eventPackageName = value === "Others" ? "" : value;
      } else {
        detail.eventPackageName = value;
      }
    }

    data[key] = detail;
    setPackageList(data);
  };

  const handleChangeInDropdown = (e) => {
    let detail = [...packageList];
    if (e.value > 0) {
      let arry = {
        eventPackageId: 0,
        eventPackageName: e.label,
        eventPrice: 0,
        eventCredit: 0,
        eventTimeSlotId: props.eventTimeSlotId,
        entryPackagePrice: [
          {
            eventPriceId: 0,
            eventPackageId: 0,
            earlyBirdPrice: 0,
            isSuperEarlyBirdPrice: false,
            promotionStartDate: '',
            promotionEndDate: '',
            maxQuantity: 0
          },
          {
            eventPriceId: 0,
            eventPackageId: 0,
            earlyBirdPrice: 0,
            isSuperEarlyBirdPrice: true,
            promotionStartDate: '',
            promotionEndDate: '',
            maxQuantity: 0
          }
        ]
      }
      detail.push(arry)
      setPackageList(detail)
      setIsAdd(-1)
    }
  }
  const deleteEntryPricingPackage = (key, packageId) => {
    let data = [...packageList];
    data.splice(key, 1);
    setPackageList(data);
  }

  const handlePriceChange = (e, key, packageName) => {
    let value = e.target.value;
    // const id = e.target.id;
    let name = e.target.name;
    const nameArr = name.split("_");
    const index = nameArr[0] === 'EarlyBirdPrice' ? 0 : 1;
    name = nameArr[1];

    let packageIndex = packageList.findIndex(x => x.eventPackageName === packageName);
    let mainPack = [...packageList];
    let singlePack = { ...mainPack[packageIndex] };
    let birdArr = [...singlePack.entryPackagePrice];



    if (packageIndex !== -1) {

      switch (nameArr[1]) {
        case 'earlyBirdPrice':
          if ((value = convertToDecimal(value)) || value === 0) {

            birdArr[index] = {
              ...birdArr[index],
              [name]: value,
            };
          }

          break;

        case 'earlyBirdCredit':
          if ((value = convertToDecimal(value)) || value === 0) {

            birdArr[index] = {
              ...birdArr[index],
              [name]: value,
            };
          }

          break;
        case 'maxQuantity':
          value = (!value || value === '') ? null : value;
          value = isNaN(value) ? 0 : Number(value);

          birdArr[index] = {
            ...birdArr[index],
            [name]: (value < 0) ? 0 : value,
          };
          break;
        default:
          birdArr[index] = {
            ...birdArr[index],
            [name]: (value < 0) ? 0 : value,
          };
          break;
      }
      mainPack[packageIndex] = { ...mainPack[packageIndex], entryPackagePrice: birdArr };
      setPackageList([...mainPack]);
    } else {

      setPackageList([...mainPack]);
    }
  }
  // const scrollTo1 = (e) => {
  //   scroller.scrollTo(500, {
  //     duration: 800,
  //     delay: 0,
  //     smooth: 'easeInOutQuart'
  //   });
  // };
  return (
    <div>
      {/* Validation Section */}
      {props.validStateAdditionalDescription.error.length > 0 ||
        props.validState.error.length > 0 ||
        props.validStateSpeaker.error.length > 0 ||
        props.validStateModerator.error.length > 0 ||
        props.validStatePrice.error.length > 0 ?
        <div className="bg-white" name="ErrorList" id="ErrorList">
          <div className="heading-part pl-7 py-4 border-b mb-5">
            <div className="flex items-center justify-between">
              <h4 className="small-title font-bold theme-color">
                The following field(s) are required:
              </h4>
            </div>
            <ul>
              {props.validState.error.map((e, key) => {
                return <li key={key} className=" text-[#c00000]">{e}</li>;
              })}
              {props.validStateAdditionalDescription.error
                ? props.validStateAdditionalDescription.error.map((e, key) => {
                  return <li key={key} className=" text-[#c00000]">{e}</li>;
                })
                : null}
              {props.validStateSpeaker.error
                ? props.validStateSpeaker.error.map((e, key) => {
                  return <li key={key} className=" text-[#c00000]">{e}</li>;
                })
                : null}
              {props.validStateModerator.error
                ? props.validStateModerator.error.map((e, key) => {
                  return <li key={key} className=" text-[#c00000]">{e}</li>;
                })
                : null}
              {props.validStatePrice.error
                ? props.validStatePrice.error.map((e, key) => {
                  return <li key={key} className=" text-[#c00000]">{e}</li>;
                })
                : null}

            </ul>
          </div>
        </div>
        : null
      }

      {/* Basic Information Start */}
      <div className="bg-white">
        <div className="heading-part pl-7 py-4 border-b">
          <div className="flex items-center justify-between">
            <h4 className="small-title font-bold theme-color">
              Basic Information 
            </h4>
          </div>
        </div>
        <div className="px-5 py-4 pt-10">
          <div className="mb-10">
            <div className="grid grid-cols-12 gap-6">
              <div className="2xl:col-span-8 lg:col-span-8 col-span-12">
                <div className="grid grid-cols-12 gap-6">
                  <div className="2col-span-12 lg:col-span-12 col-span-12">
                    <div className="grid grid-cols-12 gap-6 mb-6">
                      <div className="2xl:col-span-8 lg:col-span-9 col-span-12">
                        <label
                          className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                          htmlFor="EntryName"
                        >
                          Entry Name <span className="text-[#C00000]">*</span>
                        </label>
                        <input
                          id="EntryName"
                          className="form-input rounded-none w-full shadow-red py-3 text-lg text-[#757575]"
                          type="text"
                          name="eventName"
                          value={props.basicInformationDetails.eventName}
                          onChange={(event) =>
                            props.handleChange(event, "BasicInformation")
                          }
                        // onBlur={() => props.validateField("eventName")}
                        />
                      </div>
                    </div>
                    <div className="grid grid-cols-12 gap-6  mb-6">
                      <div className="2xl:col-span-8 lg:col-span-9 col-span-12">
                        <label
                          className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                          htmlFor="default"
                        >
                          Entry Type <span className="text-[#C00000]">*</span>{" "}
                        </label>
                        <Selector
                          options={props.entryType}
                          // isMulti
                          closeMenuOnSelect={true}
                          hideSelectedOptions={false}
                          // components={{
                          //   Option,
                          // }}
                          onChange={props.handleChangeInDropdown}
                          allowSelectAll={false}
                          value={props.entryType.filter(
                            (item) =>
                              item.value ===
                              props.basicInformationDetails.eventTypeId
                          )}
                          drpIdentity="EntryType"
                          disabled={props.basicInformationDetails.eventId > 0 ? true : false}
                        />
                      </div>
                    </div>
                    {props.basicInformationDetails.eventTypeId === 1 ?
                      <div className="grid grid-cols-12 gap-6 mb-6">
                        <div className="2xl:col-span-8 lg:col-span-9 col-span-12">
                          <label
                            className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                            htmlFor="workshopFlagTag"
                          >
                            Workshop Flag Tag
                          </label>
                          <input
                            id="workshopFlagTag"
                            className="form-input rounded-none w-full shadow-red py-3 text-lg text-[#757575]"
                            type="text"
                            name="workshopFlagTag"
                            value={props.basicInformationDetails.workshopFlagTag}
                            onChange={(event) =>
                              props.handleChange(event, "BasicInformation")
                            }
                          // onBlur={() => props.validateField("eventName")}
                          />
                        </div>
                      </div>
                      : null}

                    <div className="grid grid-cols-12 gap-6 mb-6">
                      <div className="2xl:col-span-8 lg:col-span-9 col-span-12">
                        <label
                          className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                          htmlFor="EntryTopic"
                        >
                          Entry Topic <span className="text-[#C00000]">*</span>{" "}
                        </label>
                        <Selector
                          options={props.entryTopics}
                          isMulti
                          closeMenuOnSelect={false}
                          hideSelectedOptions={false}
                          // components={{
                          //   Option,
                          // }}
                          onChange={props.handleChangeInDropdown}
                          allowSelectAll={true}
                          value={props.basicInformationDetails.selectedTopicIds}
                          drpIdentity="EntryTopic"
                        />
                      </div>
                    </div>
                    <div className="grid grid-cols-12 gap-6 mb-6">
                      <div className=" col-span-7 w-full">
                        <label
                          className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                          htmlFor="eventRegistrationApproval"
                        >
                          Registration Approval
                          <span className="text-[#C00000]">*</span>{" "}
                        </label>
                        <div className="flex items-center justify-start">
                          <div className="m-3 mr-20">
                            <label className="flex items-center">
                              <input
                                type="radio"
                                name="eventRegistrationApproval"
                                id="eventRegistrationApproval"
                                className="form-radio"
                                value={true}
                                onChange={(event) =>
                                  props.handleRadioButtons(event)
                                }
                                checked={
                                  props.basicInformationDetails
                                    .eventRegistrationApproval === "true"
                                }
                              />
                              <span className="text-xl  font-medium theme-color ml-2">
                                Yes
                              </span>
                            </label>
                          </div>

                          <div className="m-3">
                            <label className="flex items-center">
                              <input
                                type="radio"
                                name="eventRegistrationApproval"
                                id="eventRegistrationApproval"
                                className="form-radio"
                                value={false}
                                onChange={(event) =>
                                  props.handleRadioButtons(event)
                                }
                                checked={
                                  props.basicInformationDetails
                                    .eventRegistrationApproval === "false"
                                }
                              />
                              <span className="text-xl  font-medium theme-color ml-2">
                                No
                              </span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    {props.basicInformationDetails.eventRegistrationApproval === "true" ?
                      <div className="grid grid-cols-12 gap-6 mb-6">
                        <div className="2xl:col-span-8 lg:col-span-9 col-span-12">
                          <label
                            className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                            htmlFor="notificationPeriod"
                          >
                            Notification Period{" "}
                            <span className="text-[#C00000]">*</span>
                          </label>
                          <input
                            id="notificationPeriod"
                            className="form-input rounded-none w-full shadow-red py-3 text-lg text-[#757575]"
                            type="text"
                            name="eventNotificationPeriod"
                            value={
                              props.basicInformationDetails
                                .eventNotificationPeriod
                            }
                            onChange={(event) =>
                              props.handleChange(event, "BasicInformation")
                            }
                          />
                          <div>
                            {" "}
                            <ValidationText
                            // error={props.validState.error.resourceName}
                            />{" "}
                          </div>
                        </div>
                      </div>
                      : null}

                    {props.basicInformationDetails.eventTypeId === 1 || props.basicInformationDetails.eventTypeId === 4 || props.basicInformationDetails.eventTypeId === 5 || props.basicInformationDetails.eventTypeId === 6 ?

                      <div className="grid grid-cols-12 gap-6">
                        <div className="2xl:col-span-12 lg:col-span-12 col-span-12">
                          <label
                            className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                            htmlFor="default"
                          >
                            About <span className="text-[#C00000]">*</span>
                          </label>
                          <div className="ck-editor-sec">
                            {/* <MyEditor
                              key={`Workshop_About_CkEditor`}
                              onChange={(event, editor) => {
                                const data = editor.getData();
                                props.setEditorData(data, "eventAbout");
                              }}
                              data={props.basicInformationDetails.eventAbout}
                            /> */}
                            <TinyMCEEditor
                              key={"Workshop_About_CkEditor"}
                              onChange={(data) => {
                                props.setEditorData(data, "eventAbout");
                              }}
                              data={props.basicInformationDetails.eventAbout}
                            />
                          </div>
                        </div>
                      </div>

                      : null}
                    {props.basicInformationDetails.eventTypeId === 2 ?

                      <div className="grid grid-cols-12 gap-6">
                        <div className="2xl:col-span-12 lg:col-span-12 col-span-12">
                          <label
                            className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                            htmlFor="default"
                          >
                            About <span className="text-[#C00000]">*</span>
                          </label>
                          <div className="ck-editor-sec">
                            {/* <MyEditor
                              key={`Conference_About_CkEditor`}
                              onChange={(event, editor) => {
                                const data = editor.getData();
                                props.setEditorData(data, "eventAbout");
                              }}
                              data={props.basicInformationDetails.eventAbout}
                            /> */}
                            <TinyMCEEditor
                              key={`Conference_About_CkEditor`}
                              onChange={(data) => {
                                props.setEditorData(data, "eventAbout");
                              }}
                              data={props.basicInformationDetails.eventAbout}
                            />
                          </div>
                        </div>
                      </div>

                      : null}
                    {props.basicInformationDetails.eventTypeId === 3 || props.basicInformationDetails.eventTypeId === 7?

                      <div className="grid grid-cols-12 gap-6">
                        <div className="2xl:col-span-12 lg:col-span-12 col-span-12">
                          <label
                            className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                            htmlFor="default"
                          >
                            About <span className="text-[#C00000]">*</span>
                          </label>
                          <div className="ck-editor-sec">
                            {/* <MyEditor
                              key={`Form_About_CkEditor`}
                              onChange={(event, editor) => {
                                const data = editor.getData();
                                props.setEditorData(data, "eventAbout");
                              }}
                              data={props.basicInformationDetails.eventAbout}
                            /> */}
                            <TinyMCEEditor
                              key={`Form_About_CkEditor`}
                              onChange={(data) => {
                                props.setEditorData(data, "eventAbout");
                              }}
                              data={props.basicInformationDetails.eventAbout}
                            />
                          </div>
                        </div>
                      </div>

                      : null}
                  </div>
                </div>
              </div>

              <div className="2xl:col-span-4 lg:col-span-4 col-span-12">
                <ImageCropperWithUpload
                  key="Main"
                  KeyId="EventImage"
                  height={460}
                  width={814}
                  isCheckValidation={true}
                  MaxFileSize={2097152}
                  MaxFileSizeMessage={"2MB"}
                  onSaveImage={(file, id, imageIdentity) =>
                    props.saveMainImage(file, id, imageIdentity)
                  }
                  imageIdentity={"\\Events\\BasicEventInformation"}
                  intitImage={props.basicInformationDetails.eventImage}
                  aspactRatio={814 / 460}
                  uploadBtn={"Upload Event Image"}
                  dimensionText={
                    "(Dimension: 814px by 460px Maximum Size: 1MB)"
                  }
                />
              </div>
            </div>
            {/* New Fields Added */}
            {props.basicInformationDetails.eventTypeId === 1 || props.basicInformationDetails.eventTypeId === 4 || props.basicInformationDetails.eventTypeId === 5 || props.basicInformationDetails.eventTypeId === 6 
 ? (
              <>
                <div className="grid grid-cols-12 gap-6">
                  <div className="2xl:col-span-8 lg:col-span-8 col-span-12">
                    <div className="newfields-add">
                      <div className="grid grid-cols-12 gap-6 mt-5">
                        <div className="2xl:col-span-12 lg:col-span-12 col-span-12">
                          <label
                            className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                            htmlFor="default"
                          >
                            Outlines <span className="text-[#C00000]">*</span>
                          </label>
                          <div className="ck-editor-sec">
                            {/* <MyEditor
                              // key={index}
                              onChange={(event, editor) => {
                                const data = editor.getData();
                                props.setEditorData(data, "outlineDescription");
                              }}
                              data={
                                props.basicInformationDetails.outlineDescription
                              }
                            /> */}
                            <TinyMCEEditor
                              key={`Workshop_Outlines_CkEditor`}
                              onChange={(data) => {
                                props.setEditorData(data, "outlineDescription");
                              }}
                              data={props.basicInformationDetails.outlineDescription}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-12 gap-6 items-start">
                  <div className="2xl:col-span-8 lg:col-span-8 col-span-12">
                    <div className="newfields-add">
                      <div className="grid grid-cols-12 gap-6 mt-5">
                        <div className="2xl:col-span-12 lg:col-span-12 col-span-12">
                          <label
                            className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                            htmlFor="default"
                          >
                            Speaker
                          </label>
                          <div className="ck-editor-sec">
                            {/* <MyEditor
                              // key={index}
                              onChange={(event, editor) => {
                                const data = editor.getData();
                                props.setEditorData(data, "speakerDescription");
                              }}
                              data={
                                props.basicInformationDetails.speakerDescription
                              }
                            /> */}
                            <TinyMCEEditor
                              key={`Workshop_Speaker_CkEditor`}
                              onChange={(data) => {
                                props.setEditorData(data, "speakerDescription");
                              }}
                              data={props.basicInformationDetails.speakerDescription}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="grid grid-cols-12 gap-6 mt-5">
                        <div className="2xl:col-span-12 lg:col-span-12 col-span-12">
                          <label
                            className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                            htmlFor="default"
                          >
                            Read More <span className="text-[#C00000]">*</span>
                          </label>
                          <div className="ck-editor-sec">
                            {/* <MyEditor
                              // key={index}
                              onChange={(event, editor) => {
                                const data = editor.getData();
                                props.setEditorData(
                                  data,
                                  "readMoreDescription"
                                );
                              }}
                              data={
                                props.basicInformationDetails
                                  .readMoreDescription
                              }
                            /> */}
                            <TinyMCEEditor
                              key={`Workshop_ReadMore_CkEditor`}
                              onChange={(data) => { props.setEditorData(data, "readMoreDescription"); }}
                              data={props.basicInformationDetails.readMoreDescription}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="2xl:col-span-4 lg:col-span-4 col-span-12 mt-14">
                    <div className="speaker-img-cropper">
                      <ImageCropperWithUpload
                        key="SpeakerImage"
                        keyId={"SpeakerImage"}
                        height={200}
                        width={200}
                        isCheckValidation={true}
                        MaxFileSize={2097152}
                        MaxFileSizeMessage={"2MB"}
                        onSaveImage={(file, id, imageIdentity) =>
                          props.saveMainImage(file, id, imageIdentity)
                        }
                        imageIdentity={"\\Events\\BasicEventInformation"}
                        intitImage={props.basicInformationDetails.speakerImage}
                        aspactRatio={200 / 200}
                        uploadBtn={"Upload Speaker Image"}
                      />
                    </div>
                  </div>
                </div>
              </>
            ) : null}
          </div>
        </div>
      </div>
      {/* Basic Information End */}

      {props.basicInformationDetails.eventTypeId === 2 ? (
        <>
          {/* Additional Description Start */}
          <div className="bg-white mt-10 pb-1">
            <div className="heading-part pl-7 py-4 border-b">
              <div className="flex items-center justify-between">
                <h4 className="small-title font-bold theme-color">
                  Additional Description
                </h4>
              </div>
            </div>
            <div className="px-5">
              <div className="">
                {/* {props.resource.isPage || props.resource.isVideo ? ( */}
                <div className="grid grid-cols-12 gap-6 py-7 pr-0">
                  <div className="col-span-12">
                    {/* {props.additionalDescription.map((value, index) => {
                      return (
                        <AdditionalDescription
                          descriptionDetails={value}
                          setEditorData={props.setEditorData}
                          saveMainImage={props.saveMainImage}
                          identity={index}
                        />
                      );
                    })} */}
                    <AdditionalDescription
                      additionalDescription={props.additionalDescription}
                      // descriptionDetails={value}
                      setEditorData={props.setEditorData}
                      saveMainImage={props.saveMainImage}
                      // removeAdditionalDescription={(index) => props.removeAdditionalDescription(index)}
                      deleteAdditionalDescriptionById={(index) => props.deleteAdditionalDescriptionById(index)}
                    // identity={index}
                    />

                    <button
                      className=" px-10 py-3 btn-red text-xl border text-white"
                      onClick={() => props.addDescription()}
                    >
                      <div className="flex items-center font-semibold">
                        <span className="mr-3">
                          <img src="../plus-round-icon.svg" alt=""></img>
                        </span>
                        <span className="">Add Description</span>
                      </div>
                    </button>
                  </div>
                </div>
                {/* ) : null} */}
              </div>
            </div>
          </div>
          {/* Additional Description End */}
          {/* Speaker Start */}
          <div className="bg-white mt-10 pb-7">
            <div className="heading-part pl-7 py-4 border-b">
              <div className="flex items-center justify-between">
                <h4 className="small-title font-bold theme-color">Speaker</h4>
              </div>
            </div>
            {props.additionalSpeaker.map((value, index) => {
              return (
                <div key={index}>
                  <AdditionalSpeaker
                    speakerDetails={value}
                    setEditorData={props.setEditorData}
                    saveMainImage={props.saveMainImage}
                    identity={index}
                    handleChange={props.handleChange}
                    // removeAdditionalSpeaker={(index) => props.removeAdditionalSpeaker(index)}
                    removeAdditionalSpeaker={(index) => props.deleteSpeakerById(index)}
                  />
                </div>
              );
            })}

            {/* Add Speaker button */}
            <div className="px-5">
              <div className="grid grid-cols-12 gap-6 pb-1 pt-7">
                <div className="col-span-12">
                  <button
                    className="px-10 py-3 btn-red text-xl border text-white"
                    onClick={() => props.addSpeaker()}
                  >
                    <div className="flex items-center font-semibold">
                      <span className="mr-3">
                        <img src="../plus-round-icon.svg" alt=""></img>
                      </span>
                      <span className="">Add Speaker</span>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* Speaker End */}
          {/* Moderator Start */}
          <div className="bg-white mt-10">
            <div className="heading-part pl-7 py-4 border-b">
              <div className="flex items-center justify-between">
                <h4 className="small-title font-bold theme-color">Moderator</h4>
              </div>
            </div>
            <div className="px-5">
              <div className="">
                <div className="grid grid-cols-12 gap-6 py-7 pb-8">
                  <div className="2xl:col-span-12 lg:col-span-12 col-span-12">
                    <div className="grid grid-cols-12 gap-6">
                      <div className="2col-span-12 lg:col-span-12 col-span-12">
                        {props.additionalModerator.map((value, index) => {
                          return (
                            <div key={index}>
                              <AdditionalModerator
                                speakerDetails={value}
                                setEditorData={props.setEditorData}
                                saveMainImage={props.saveMainImage}
                                identity={index}
                                handleChange={props.handleChange}
                                // removeAdditionalModerator={(index) => props.removeAdditionalModerator(index)}
                                removeAdditionalModerator={(index) => props.deleteModeratorById(index)}
                              />
                            </div>
                          );
                        })}
                        <div className="">
                          <button
                            className="px-10 py-3 btn-red text-xl border text-white"
                            onClick={() => props.addModerator()}
                          >
                            <div className="flex items-center font-semibold">
                              <span className="mr-3">
                                <img src="../plus-round-icon.svg" alt=""></img>
                              </span>
                              <span className="">Add Moderator</span>
                            </div>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Moderator End */}
        </>
      ) : null}

      {props.basicInformationDetails.eventTypeId === 3  || props.basicInformationDetails.eventTypeId == 7? (
        <>
          {/* Apply before specific date Start*/}
          <div className="bg-white mt-10">
            <div className="heading-part pl-7 py-4 border-b">
              <div className="flex items-center justify-between">
                <h4 className="small-title font-bold theme-color">
                  Apply before specific date
                </h4>
              </div>
            </div>
            <div className="px-9 py-9 pt-7">
              <div className="grid grid-cols-12 gap-6">
                <div className="2xl:col-span-8 lg:col-span-10 col-span-12">
                  <div className="grid grid-cols-12 gap-6">
                    <div className="2xl:col-span-4 lg:col-span-4 col-span-12">
                      <label
                        className="block text-xl font-medium 2xl:mb-3 lg:[0.25rem] theme-color min-h-30"
                        htmlFor="eventFormStartDate"
                      >
                        Start Date
                        <span className="text-[#C00000]">*</span>
                      </label>
                      <div className="grid grid-cols-12 gap-6 items-center w-full">
                        <div className="2xl:col-span-12 lg:col-span-12 col-span-12 w-full">
                          <input
                            id="eventFormStartDate"
                            className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                            type="date"
                            name="eventFormStartDate"
                            value={moment(props.basicInformationDetails.eventFormStartDate).format("yyyy-MM-DD")}
                            onChange={(event) =>
                              props.handleChange(event, "BasicInformation")
                            }
                            min={moment(new Date()).format("yyyy-MM-DD")}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="2xl:col-span-4 lg:col-span-4 col-span-12">
                      <label
                        className="block text-xl font-medium 2xl:mb-3 lg:[0.25rem] theme-color min-h-30"
                        htmlFor="eventFormEndDate"
                      >
                        End Date
                        <span className="text-[#C00000]">*</span>
                      </label>
                      <div className="grid grid-cols-12 gap-6 items-center w-full">
                        <div className="2xl:col-span-12 lg:col-span-12 col-span-12 w-full">
                          <input
                            id="eventFormEndDate"
                            className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                            type="date"
                            name="eventFormEndDate"
                            value={moment(props.basicInformationDetails.eventFormEndDate).format("yyyy-MM-DD")}
                            onChange={(event) =>
                              props.handleChange(event, "BasicInformation")
                            }
                            //min={moment(new Date()).format("yyyy-MM-DD")}
                            min={props.basicInformationDetails.eventFormStartDate ? moment(props.basicInformationDetails.eventFormStartDate).format("yyyy-MM-DD") : moment(new Date()).format("yyyy-MM-DD")}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="2xl:col-span-4 lg:col-span-4 col-span-12">
                      <label
                        className="block text-xl font-medium 2xl:mb-3 lg:[0.25rem] theme-color min-h-30"
                        htmlFor="default"
                      >
                        End Tme
                        <span className="text-[#C00000]">*</span>
                      </label>
                      <div className="grid grid-cols-12 gap-6 items-center w-full">
                        <div className="2xl:col-span-12 lg:col-span-12 col-span-12 w-full">
                          <TimePicker
                            className="handleClicksForTimePicker"
                            identity={"eventFormEndTime"}
                            setTime={(time) => props.setTime(time)}
                            value={props.basicInformationDetails.eventFormEndTime}
                          />
                          {/* <div>
                              <ValidationText
                              error={props.validState.error.unpublishDate}
                              />
                            </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Apply before specific date End*/}

          <div className="bg-white mt-10">
            <div className="heading-part pl-7 py-4 border-b">
              <div className="flex items-center justify-between">
                <h4 className="small-title font-bold theme-color">Pricing</h4>
              </div>
            </div>
            <div className="px-9 py-7">
              <div className="grid grid-cols-12 gap-6">
                <div className="2xl:col-span-12 lg:col-span-12 col-span-12">
                  <div className="grid grid-cols-12 gap-6">
                    <div className="2xl:col-span-12 lg:col-span-12 col-span-12">
                      <div className="grid grid-cols-12 gap-6">
                        <div className=" col-span-7 w-full">
                          <div className="flex flex-col justify-start">
                            <div className="my-3">
                              <label className="flex items-center">
                                <input
                                  type="radio"
                                  name="radio-buttons-isAttendanceFree"
                                  id="isAttendanceFree"
                                  className="form-radio"
                                  onChange={(event) => {
                                    props.handleChange(event);
                                    if (event.target.checked === true) {
                                      setIsEdit(0)
                                    }
                                  }}
                                  value={true}
                                  checked={props.basicInformationDetails.isAttendanceFree === "true"}
                                />
                                <span className="text-xl  font-medium theme-color ml-2">
                                  Attendance is free
                                </span>
                              </label>
                            </div>

                            <div className="my-3">
                              <label className="flex items-center">
                                <input
                                  type="radio"
                                  name="radio-buttons-isAttendanceFree"
                                  id="isAttendanceFree"
                                  className="form-radio"
                                  onChange={(event) => props.handleChange(event)}
                                  value={false}
                                  checked={props.basicInformationDetails.isAttendanceFree === "false"}
                                />
                                <span className="text-xl  font-medium theme-color ml-2">
                                  There is a cost to attend
                                </span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Pricing Details Start */}
          <div className="bg-white mt-12 pb-1">
            <div className="heading-part pl-7 py-4 border-b">
              <div className="flex items-center justify-between">
                <h4 className="small-title font-bold theme-color">
                  Pricing Details
                </h4>
                {/* Akshay : To do : Uncomment bellow code while Credit Allow */}
                {/* <div className="2xl:col-span-4 lg:col-span-4 col-span-12 flex justify-end pr-10">
                  <div className="check-box-sec flex items-center">
                    <input
                      id="isCreditAllow"
                      name="isCreditAllow"
                      type="checkbox"
                      className="form-checkbox"
                      checked={isCreditAllow}
                      value={isCreditAllow}
                      onChange={(e) => setIsCreditAllow(e.target.checked)}
                    />
                    <span className="filter-lable font-bold text-[#757575] pl-4">
                      Credit Allow
                    </span>
                  </div>
                </div> */}
              </div>
            </div>
            <div className="m-8 mb-7 border border-[#757575]">
              <div className="grid grid-cols-12 gap-6">
                <div className="2xl:col-span-12 lg:col-span-12 col-span-12">
                  <div className="px-7 py-4 pricing-details-title-part border-b border-[#757575]">
                    <div className="grid grid-cols-12 gap-6">
                      <div className="2xl:col-span-3 lg:col-span-3 col-span-12">
                        <span className="text-xl font-medium theme-color">
                          Package
                          <span className="text-[#C00000]">*</span>
                        </span>
                      </div>
                      <div className="2xl:col-span-3 lg:col-span-3 col-span-12">
                        <span className="text-xl font-medium theme-color">
                          Price
                          <span className="text-[#C00000]">*</span>
                        </span>
                      </div>
                      <div className="2xl:col-span-6 lg:col-span-6 col-span-12">
                        <span className="text-xl font-medium theme-color">
                          Credit
                          <span className="text-[#C00000]">*</span>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="pricing-details">
                    <div className="p-7 pb-5">
                      {packageList.map((pack, key) => (
                        <>
                          <div className="grid grid-cols-12 gap-6 mb-6 relative z-10" key={key}>
                            <div className="2xl:col-span-3 lg:col-span-3 col-span-12">
                              {pack.eventPackageName === "Non Member" ||
                                pack.eventPackageName === "Ordinary Member" ||
                                pack.eventPackageName === "Associate Member" ||
                                pack.eventPackageName === "Qualifying Member" ||
                                pack.eventPackageName === "Others"
                                ?
                                <TextInput
                                  className={"bg-[#e3e3e3]"}
                                  type="Text"
                                  id={`eventPackageName_${key}`}
                                  name="eventPackageName"
                                  value={pack.eventPackageName}
                                  onChange={(e) => handleChange(e, key)}
                                  disabled={true}
                                />
                                :
                                <TextInput
                                  className={" bg-[#e3e3e3] w-full"}
                                  type="Text"
                                  id={`eventPackageName_${key}`}
                                  name="eventPackageName"
                                  value={pack.eventPackageName === "Others" ? "" : pack.eventPackageName}
                                  // value={pack.label === "Others"?"":pack.label}
                                  onChange={(e) => handleChange(e, key)}
                                  disabled={true}
                                />
                              }
                            </div>
                            <div className="2xl:col-span-3 lg:col-span-3 col-span-12  settings-section">

                              <div className="sign-icon-input">
                                <div className="relative dollar-input">
                                  <div className="sign-sec">
                                    <input
                                      className={`w-full py-3 text-lg ${props.basicInformationDetails.isAttendanceFree === "true" ? "bg-[#e3e3e3]" : null}`}

                                      id={`eventBasePrice_${key}`}
                                      type="text"
                                      name="eventPrice"
                                      value={pack.eventPrice}
                                      onChange={(e) => handleChange(e, key)}
                                      disabled={props.basicInformationDetails.isAttendanceFree === "true"}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="2xl:col-span-6 lg:col-span-6 col-span-12">
                              <div className="grid grid-cols-12 gap-6 items-center">
                                <div className="2xl:col-span-7 lg:col-span-7 col-span-12">
                                  <input
                                    className={`w-full py-3 text-lg ${props.basicInformationDetails.isAttendanceFree === "true" || (isCreditAllow === true ? null : "bg-[#e3e3e3]") ? "bg-[#e3e3e3]" : null}`}
                                    id={`eventBaseCredit_${key}`}
                                    type="text"
                                    name="eventCredit"
                                    value={pack.eventCredit}
                                    onChange={(e) => handleChange(e, key)}
                                    disabled={!(props.basicInformationDetails.isAttendanceFree === "true" || isCreditAllow === true)}
                                  />
                                </div>
                                <div className="2xl:col-span-5 lg:col-span-5 col-span-12">
                                  <div className="setting-delete-button">

                                    {props.basicInformationDetails.isAttendanceFree === "true" ? null :
                                      <div className="flex items-center justify-around relative">
                                        <button
                                          className={`action-btn right-icon btn-settings ${isEdit === key + 1 ? "active-btn" : ""
                                            }`}
                                          // className="action-btn right-icon active-btn"
                                          onClick={() => setIsEdit(key + 1)}
                                        >
                                          <SettingsButtonIcon />
                                        </button>
                                        <button
                                          className={`action-btn right-icon btn-settings ${isEdit === key + 1 ? "ml-[103px]" : ""
                                            }`}

                                          onClick={() => deleteEntryPricingPackage(key, pack.eventPackageId)}
                                        >
                                          <DeleteButtonIcon />
                                        </button>
                                      </div>
                                    }
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {isEdit === key + 1 ? (
                            <div className="grid grid-cols-12 gap-6 mb-6 relative">
                              <div className="2xl:col-span-12 lg:col-span-12 col-span-12">
                                <div className="bg-[#f0f0f1] p-8 w-full settings-section">
                                  <div className="grid grid-cols-12 gap-6 pb-10">
                                    <div className="2xl:col-span-2 lg:col-span-2 col-span-12">
                                      <label
                                        className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                        htmlFor={`earlyBirdPrice${key}`}
                                      >
                                        Early Bird:
                                        {/* <span className="text-[#C00000]">*</span> */}
                                      </label>
                                      <div className="sign-icon-input">
                                        <div className="relative dollar-input">
                                          <div className="sign-sec">
                                            <input
                                              className="w-full py-3 text-lg"
                                              name="EarlyBirdPrice_earlyBirdPrice"
                                              type="text"
                                              id={`earlyBirdPrice${key}`}
                                              //value={eventPricing.entryPackage[key].entryPackagePrice[0].earlyBirdPrice}
                                              value={pack.entryPackagePrice[0].earlyBirdPrice}
                                              onChange={(e) => handlePriceChange(e, key, pack.eventPackageName)}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="2xl:col-span-2 lg:col-span-2 col-span-12">
                                      <label
                                        className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                        htmlFor={`earlyBirdCredit${key}`}
                                      >
                                        Credit:
                                      </label>
                                      <input
                                        className={`w-full py-3 text-lg ${isCreditAllow === true ? null : "bg-[#e3e3e3]"}`}
                                        name="EarlyBirdPrice_earlyBirdCredit"
                                        type="text"
                                        id={`earlyBirdCredit${key}`}
                                        value={pack.entryPackagePrice[0].earlyBirdCredit}
                                        onChange={(e) => handlePriceChange(e, key, pack.eventPackageName)}
                                        disabled={isCreditAllow !== true}
                                      />
                                    </div>
                                    <div className="2xl:col-span-3 lg:col-span-3 col-span-12">
                                      <label
                                        className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                        htmlFor={`promotionStartDate${key}`}
                                      >
                                        Promotion Start Date:
                                        {/* <span className="text-[#C00000]">*</span> */}
                                      </label>
                                      <input
                                        type="date"
                                        className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                                        name="EarlyBirdPrice_promotionStartDate"
                                        id={`promotionStartDate${key}`}
                                        value={moment(pack.entryPackagePrice[0].promotionStartDate).format("yyyy-MM-DD")}
                                        onChange={(e) => handlePriceChange(e, key, pack.eventPackageName)}

                                      />
                                    </div>
                                    <div className="2xl:col-span-3 lg:col-span-3 col-span-12">
                                      <label
                                        className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                        htmlFor={`promotionEndDate${key}`}
                                      >
                                        Promotion End Date:
                                        {/* <span className="text-[#C00000]">*</span> */}
                                      </label>
                                      <input
                                        type="date"
                                        className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                                        name="EarlyBirdPrice_promotionEndDate"
                                        id={`promotionEndDate${key}`}
                                        value={moment(pack.entryPackagePrice[0].promotionEndDate).format("yyyy-MM-DD")}
                                        onChange={(e) => handlePriceChange(e, key, pack.eventPackageName)}

                                      />
                                    </div>
                                    <div className="2xl:col-span-2 lg:col-span-2 col-span-12">
                                      <label
                                        className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                        htmlFor={`maxQuantity${key}`}
                                      >
                                        Max Quantity:
                                        {/* <span className="text-[#C00000]">*</span> */}
                                      </label>
                                      <input
                                        type="text"
                                        className="w-full py-3 text-lg"
                                        id={`maxQuantity${key}`}
                                        name="EarlyBirdPrice_maxQuantity"
                                        onChange={(e) => handlePriceChange(e, key, pack.eventPackageName)}
                                        value={pack.entryPackagePrice[0].maxQuantity}
                                      />
                                    </div>
                                  </div>
                                  <div className="grid grid-cols-12 gap-6 pt-7 border-t border-[#757575]">
                                    <div className="2xl:col-span-2 lg:col-span-2 col-span-12">
                                      <label
                                        className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                        htmlFor={`superEarlyBirdPrice${key}`}
                                      >
                                        Super Early Bird:
                                        {/* <span className="text-[#C00000]">*</span> */}
                                      </label>
                                      <div className="sign-icon-input">
                                        <div className="relative dollar-input">
                                          <div className="sign-sec">
                                            <input
                                              type="text"
                                              className="w-full py-3 text-lg"
                                              id={`superEarlyBirdPrice${key}`}
                                              name="SuperEarlyBird_earlyBirdPrice"
                                              //value={eventPricing.entryPackage[key].entryPackagePrice[1].earlyBirdPrice}
                                              value={pack.entryPackagePrice[1].earlyBirdPrice}
                                              onChange={(e) => handlePriceChange(e, key, pack.eventPackageName)}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="2xl:col-span-2 lg:col-span-2 col-span-12">
                                      <label
                                        className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                        htmlFor={`superEarlyBirdCredit${key}`}
                                      >
                                        Credit:
                                        {/* <span className="text-[#C00000]">*</span> */}
                                      </label>
                                      <input
                                        type="text"
                                        className={`w-full py-3 text-lg ${isCreditAllow === true ? null : "bg-[#e3e3e3]"}`}
                                        id={`superEarlyBirdCredit${key}`}
                                        name="SuperEarlyBird_earlyBirdCredit"
                                        //value={eventPricing.entryPackage[key].entryPackagePrice[1].earlyBirdCredit}
                                        value={pack.entryPackagePrice[1].earlyBirdCredit}
                                        onChange={(e) => handlePriceChange(e, key, pack.eventPackageName)}
                                        disabled={isCreditAllow !== true}
                                      />
                                    </div>
                                    <div className="2xl:col-span-3 lg:col-span-3 col-span-12">
                                      <label
                                        className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                        htmlFor={`superPromotionStartDate${key}`}
                                      >
                                        Promotion Start Date:
                                        {/* <span className="text-[#C00000]">*</span> */}
                                      </label>
                                      <input
                                        type="date"
                                        className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                                        id={`superPromotionStartDate${key}`}
                                        name="SuperEarlyBird_promotionStartDate"
                                        value={moment(pack.entryPackagePrice[1].promotionStartDate).format("yyyy-MM-DD")}
                                        onChange={(e) => handlePriceChange(e, key, pack.eventPackageName)}

                                      />
                                    </div>
                                    <div className="2xl:col-span-3 lg:col-span-3 col-span-12">
                                      <label
                                        className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                        htmlFor={`superPromotionEndDate${key}`}
                                      >
                                        Promotion End Date:
                                        {/* <span className="text-[#C00000]">*</span> */}
                                      </label>
                                      <input
                                        type="date"
                                        className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                                        id={`superPromotionEndDate${key}`}
                                        name="SuperEarlyBird_promotionEndDate"
                                        value={moment(pack.entryPackagePrice[1].promotionEndDate).format("yyyy-MM-DD")}
                                        onChange={(e) => handlePriceChange(e, key, pack.eventPackageName)}
                                      />
                                    </div>
                                    <div className="2xl:col-span-2 lg:col-span-2 col-span-12">
                                      <label
                                        className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                        htmlFor={`superMaxQuantity${key}`}
                                      >
                                        Max Quantity:
                                        {/* <span className="text-[#C00000]">*</span> */}
                                      </label>
                                      <input
                                        type="text"
                                        className="w-full py-3 text-lg"
                                        id={`superMaxQuantity${key}`}
                                        name="SuperEarlyBird_maxQuantity"
                                        value={pack.entryPackagePrice[1].maxQuantity}
                                        onChange={(e) => handlePriceChange(e, key, pack.eventPackageName)}
                                      />
                                    </div>
                                  </div>
                                  <div className="grid grid-cols-12 gap-6 pt-10">
                                    <div className="2xl:col-span-12 lg:col-span-12 col-span-12 text-center">
                                      <button
                                        className="text-xl text-[#81a3b8]"
                                        onClick={() => setIsEdit(0)}
                                      >
                                        Minimise settings
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : null}
                        </>
                      ))}
                      {isAdd === +1 ? (
                        <div className="grid grid-cols-12 gap-6 mt-6 mb-6">
                          <div className="2xl:col-span-6 lg:col-span-6 col-span-12">

                            <Selector
                              options={props.startupMember}
                              // isMulti
                              closeMenuOnSelect={true}
                              hideSelectedOptions={false}
                              // components={{
                              //   Option,
                              // }}
                              onChange={(e) => handleChangeInDropdown(e)}
                              allowSelectAll={false}
                              //value={packageList.value}
                              drpIdentity="startupMember"
                            />
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="add-buttons">
                    <div className="grid grid-cols-12 gap-6 border-t border-[#757575]">
                      <div className="2xl:col-span-12 lg:col-span-12 col-span-12">
                        <div className="flex items-center justify-end p-8">
                          <button
                            className={`px-10 py-3 text-xl border text-white ${props.basicInformationDetails.isAttendanceFree === "true" ? "bg-[#e3e3e3]" : "btn btn-red"}`}
                            //className=" px-10 py-3 btn-red text-xl border text-white"
                            // onClick={() => props.addNewDec()}
                            onClick={() => setIsAdd(+1)}
                            disabled={props.basicInformationDetails.isAttendanceFree === "true" ? true : false}
                          >
                            <div className="flex items-center font-semibold">
                              <span className="mr-3">
                                <img src="../plus-round-icon.svg" alt=""></img>
                              </span>
                              <span className="">Add Package</span>
                            </div>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="m-8 mb-7 mt-20">
              <div className="grid grid-cols-12 gap-6">
                <div className="2xl:col-span-4 lg:col-span-4 col-span-12">
                  <label
                    className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                    htmlFor="default"
                  >
                    Sales End Date
                    {/* <span className="text-[#C00000]">*</span> */}
                  </label>
                  <div className="grid grid-cols-12 gap-6 items-center">
                    <div className="2xl:col-span-8 lg:col-span-8 col-span-12">

                      <Selector
                        options={props.salesEndDateDays}
                        // isMulti
                        closeMenuOnSelect={true}
                        hideSelectedOptions={false}
                        // components={{
                        //   Option,
                        // }}
                        onChange={props.handleChangeInDropdown}
                        allowSelectAll={false}
                        value={props.salesEndDateDays.filter(
                          (item) =>
                            item.value ===
                            props.basicInformationDetails.eventSalesEndDate
                        )}
                        drpIdentity="SalesEndDate"
                        isNumberSelect={true}
                      />
                    </div>
                    <div className="2xl:col-span-4 lg:col-span-4 col-span-12">
                      <label
                        className="filter-lable block font-medium theme-color min-h-30"
                        htmlFor="default"
                      >
                        day(s)
                        {/* <span className="text-[#C00000]">*</span> */}
                      </label>
                    </div>
                  </div>
                </div>
                <div className="2xl:col-span-12 lg:col-span-12 col-span-12 my-4">
                  <div className="2xl:col-span-12 lg:col-span-12 col-span-12">
                    <p
                      className="filter-lable block font-medium theme-color min-h-30"
                      htmlFor="default"
                    >
                      Booking period for this entry ends X days before start of
                      course/event as indicated above.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Pricing Details End */}
        </>
      ) : null}



      {/* Course Location End */}
      {/* footer Button*/}
      <div className="grid grid-cols-12 gap-6">
        <div className="2xl:col-span-12 lg:col-span-12 col-span-12">
          <div className="flex flex-wrap justify-between space-x-10 mt-12">
            <div>
              <button
                className="w-32 px-7 py-2 btn-sm btn-gray text-lg text-white"
                onClick={() => { props.navigateTo("/ViewEditEntries"); }}>
                Back
              </button>
            </div>
            <div>
              {/* {props.resource.isPage ? ( */}
              <button
                className="w-32 px-7 py-2 mr-12 btn-sm btn-red text-lg text-white"
                onClick={() => { props.addEditEntryDetails(packageList, 0, isCreditAllow); }}>
                Save
              </button>

              <button
                className="w-32 px-7  py-2 btn-sm btn-lighblue text-lg text-white"
                onClick={() => {
                  props.addEditEntryDetails(packageList, 1, isCreditAllow);
                  // scrollTo1();
                  props.executeScroll();
                }}

              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BasicInformation;
